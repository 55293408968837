<template>
    <div class="subscrib_wrap">
        <div class="top-content">
            <a-row>
                <h1 >选择您的订阅计划</h1>

                <!-- <a-switch class="m_switch" ></a-switch> -->
                
                <div class="m_switch" v-on:click="onChangeMode">
                    <div class="m_switch_inner" :class="{move_left:modeType==0,move_right:modeType==1}"></div>
                    <div class="m_switch_text_left" :class="{color_white:modeType==0,color_black:modeType==1}">每年</div>
                    <div class="m_switch_text_right" :class="{color_black:modeType==0,color_white:modeType==1}">每月</div>
                    <div class="m_switch_tip"><img src="@/assets/images/web/subscribe/热销.png"></div>
                    
                </div>
            </a-row>
             
        </div>
        <div class="container subscrib-content" style="padding-top:60px;margin-top:-164px;">
            <a-row>
                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>省钱卡</p>
                        <p style="font-size: 14px;">零售订单专享，优惠券每月免费送</p>
                        <p v-if="modeType<=0">¥<span>116</span>/年</p>
                        <p v-if="modeType==1">¥<span>12</span>/月</p>
                        <p v-if="modeType<=0">每月仅需 <span style="color:red;">¥9.67</span></p>
                        <p v-if="modeType==1">每天仅需 <span style="color:red;">¥0.4</span></p>
                        <p @click="goSubscribe(3)">
                            <span>前往开通</span>
                        </p>
                        <p>选曲范围</p>
                        <p>全曲库（不包含音效）</p>
                        <p></p>
                        <p>省钱卡权益</p>
                        <p  v-if="modeType<=0">开通火花音悦省钱年卡，每月获得零售曲库6折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用），权益累计发放12个月，当月立即到账</p>
                        <p  v-if="modeType==1">开通省火花音悦省钱月卡，当月立即获得零售曲库8折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用），零售一单即可回本。</p>
                    </div>
                </a-col>

                 <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>个人会员</p>
                        <p style="font-size: 14px;">适合自媒体人、UP主等自由职业者</p>
                        <p v-if="modeType<=0">¥<span>299</span>/年</p>
                        <p v-if="modeType==1">¥<span>59</span>/月</p>
                        <p v-if="modeType<=0"> 每月仅需 <span style="color:red;">¥24.92</span></p>
                        <p v-if="modeType==1"> 每天仅需 <span style="color:red;">¥1.97</span></p>
                        <p  @click="goSubscribe(2)">
                            <span>前往开通</span>
                        </p>
                        <p>选曲范围</p>
                        <p>限会员专区+音效</p>
                        <p></p>
                        <p>会员权益</p>
                        <p  v-if="modeType<=0">不限下载数量<br>仅支持个人使用<br>随片永久<br>授权区域中国<br>非商用授权<br>提供电子授权书</p>
                        <p  v-if="modeType==1">10首/天<br> 仅支持个人使用<br> 随片永久<br> 授权区域中国<br> 非商用授权<br> 提供电子授权书</p>
                    </div>
                </a-col>

                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>企业会员</p>
                        <p style="font-size: 14px;">适合中小企业、乙方公司为甲方客户授权</p>
                        <p v-if="modeType<=0">¥<span>3999</span>/年</p>
                        <p v-if="modeType==1">¥<span>399</span>/月</p>
                        <p v-if="modeType<=0"> 每月仅需 <span style="color:red;">¥333.25</span></p>
                        <p v-if="modeType==1"> 每天仅需 <span style="color:red;">¥13.3</span></p>
                        <p  @click="goSubscribe(1)">
                            <span>前往开通</span>
                        </p>
                        <p>选曲范围</p><p>限会员专区+音效</p>
                        <p></p>
                        <p>会员权益</p>
                        <p v-if="modeType<=0">不限下载数量<br>同一企业多项目使用<br>随片永久<br>全球全渠道可商用<br>授权1家公司<br>提供商用授权书</p>
                        <p v-if="modeType==1">20首/天<br> 同一企业多项目使用<br> 随片永久<br> 全球全渠道可商用<br> 授权1家公司<br> 提供商用授权书</p>
                    </div>
                </a-col>

                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>企业定制</p>
                        <p style="font-size: 14px;">适合各类大型企业、国企高级定制</p>
                        <p style="height: 53px;">
                            <img src="@/assets/images/web/subscribe/信息.png">
                        </p>
                        <p >
                            联系客户经理索取报价
                        </p>
                        <p style="background: linear-gradient(180deg, #5AB2FC 0%, #3E9DE3 100%);">
                            <span @click="Chatra">扫码添加微信</span>
                        </p>
                        <p>选曲范围</p>
                        <p>按需定制</p>
                        <p></p>
                        <p style="height: 171px;margin-bottom: 1em;">
                                <img style="width: 166px;" src="@/assets/images/shangwu.png">
                        </p>
                        
                    </div>
                </a-col>
                

            </a-row>
        </div>
        
        <div class="container question">
            <a-row style="text-align:center;">
                <h1>常见问题</h1>
            </a-row>
            <a-row>
                <h3><b>1、购买个人或企业会员后是否意味着我可以任意下载曲库的全部音乐？</b></h3>
                <a-col style="width: 90%; margin-left:25px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不是，开通订阅会员后您可以免费下载会员专区以及音效库中的音乐作品，对于不带会员标识的作品（也即非会员专区作品）如需此类曲目授权需要单独购买授权。请您务必在下单前确认所需音乐是否包含在会员专区内，由于音乐产品的特殊性，成功下单后即视为已使用火花音悦概不退款，请您谅解。</a-col>
            </a-row>
            <a-row>
                <h3><b>2、购买订阅会员后，如果发现不是我想要的，可以退款吗？</b></h3>
                <a-col style="width: 90%; margin-left:25px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不可以，音乐订阅会员服务属于虚拟商品，购买成功即视为已使用，概不支持退款，请您谅解。</a-col>
            </a-row>
            <a-row>
                <h3><b>3、我是否可以购买个人会员下载音频为我的公司使用？</b></h3>
                <a-col style="width: 90%; margin-left:25px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不可以，个人会员仅允许个人内容创作者，将音乐用于个人频道发布的网络视频、音频播客、网络直播等用途，如果用于公司和商业用途，请购买企业会员。</a-col>
            </a-row>
            <a-row>
                <h3><b>4、会员到期后，之前已下载的音乐是否可以继续使用？</b></h3>
                <a-col style="width: 90%; margin-left:25px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不可以，会员有效期内下载制作完成且首次公开发表的音乐作品可在授权范围内永久授权，但不得将下载的音乐继续用于新的视频配乐。您需要重新购买企业会员后才可以继续使用。</a-col>
            </a-row>
            <a-row>
                <h3><b>5、开通企业会员后下载的音乐是否可以给多个公司使用？</b></h3>
                <a-col style="width: 90%; margin-left:25px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不可以，当前仅支持授权 1 家公司，如需要授权给多个公司使用请联系商务经理获取定制方案。</a-col>
            </a-row>
            <a-row>
                <h3><b>6、已开通会员，哪里可以获取授权协议？</b></h3>
                <a-col style="width: 90%; margin-left:25px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;开通会员并添加授权主体后下载的音乐作品会自动生成电子版授权书，可前往个人中心-授权记录订阅会员中查看。</a-col>
            </a-row>
        </div>
  </div>
</template>
<script>
import {tooltip as ATooltip} from 'ant-design-vue';
import {Switch as ASwitch} from 'ant-design-vue';
export default {
    data() {
      return {
          modeType: -1,

      };
    },
    components: {
        ATooltip,
        ASwitch,
    },
    created(){
    },
    methods: {
        goSubscribe(idx){
            if (this.modeType == 1)
            {
                idx += 3;
            }
            this.$router.push('/subscribe_v2?vipType='+idx);
        },   
        Chatra(){
            window.Chatra('openChat', true)
        },
        onChangeMode(){
            if (this.modeType<=0){
                this.modeType = 1;
            }else{
                this.modeType = 0;
            }
        }
    },
}

</script>
<style lang="scss" scoped>
.subscrib_wrap{
}
.top-content {
    width: 100%;
    background-image: url('../../assets/images/web/introduce/bg.png');
    background-size: 100%;
    text-align: center;
    height: 330px;
    color: #fff;
    padding-top: 58px;
    h1{
        font-size: 34px;
        font-weight: 600;
        color: #FFFFFF;
    }
    h2{
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
    }

    .equaity-cont{
        div:nth-child(1), div:nth-child(2){
            display: inline-block;
            vertical-align: top;
        }
        div:nth-child(2){
            width: 73%;
            text-align:left;
            margin-left:2%;
            p{
                display: block;
                color: #FFFFFF;
                margin:0;
            }
            p:nth-child(1){
                font-size: 20px;
                font-weight: 500;
                color: #FEC967;
                line-height:30px;
            }
            p:nth-child(2){
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
.subscrib-content{
    .subscrib-info > div{
        p:nth-child(1){
            margin: 0;
            padding-top:16px;
            font-size: 24px;
            font-weight: 600;
            color: #FEC967;
            line-height: 33px;
            text-align:center;
        }
        p:nth-child(2){
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #FFE4B2;
            line-height: 22px;
            height:38px;
            text-align:center;
            padding-top:8px;
        }
        p:nth-child(3){
            margin-top:11px;
            line-height: 45px;
            font-size: 12px;
            text-align:center;
            font-weight: 400;
            color: #333333;
            span{
                font-size:32px;
                font-weight: 500;
                color:#EE1E1E;
            }
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom:0;
        }
        p:nth-child(4){
            text-align:center;
        }
        p:nth-child(5){
            width: 90%;
            margin: 0 auto;
            margin-top: 18px;
            line-height: 30px;
            background: linear-gradient(180deg, #FF992B 0%, #FF7B13 100%);
            border-radius: 4px;
            text-align: center;
            cursor:pointer;
            height: 34px;
            span{
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 20px;
            }
            
        }
        p:nth-child(6){
            width: 90%;
            margin: 0 auto;
            margin-top: 18px;
            margin-bottom: 4px;
            /* height: 40px; */
            font-size: 12px;
            font-weight: 400;
            /* color: #999999; */
            line-height: 20px;
            text-align:center;
            font-weight:bold;
        }
        p:nth-child(7){
            text-align:center;
            color: red;
        }
        p:nth-child(8){
            border: 1px solid #E6E6E6;
            border-bottom: none; 
            width: 96%;
            /* left:50%; */
            margin-left : 2%; 
        }
        p:nth-child(9){
            text-align:center;
            font-weight:bold;
            margin-bottom: 0px;
        }
        p:nth-child(10){
            text-align:center;
            width: 90%;
            margin-left:5%; 
            height: 150px;
        }
        .info-row{
            width: 90%;
            margin: 0 auto;
            background: #F8FAFC;
            border-radius: 2px;
            margin-top:18px;
            margin-bottom: 25px;
            padding: 8%;
            .ant-row{
                span{
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                span:nth-child(1){
                    width: 65%;
                    color: #999999;
                    line-height: 25px;
                }
                span:nth-child(2){
                    color: #666666;
                    line-height: 25px;
                    width: 35%;
                    text-align:right;
                }
            }
        }
    }
    .subscrib-info > div{
        width: 90%;
        margin: 4% auto;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DADADA;
    }

    .subscrib-info:nth-child(1){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #F6F3F0;
                color: #333333 ;
                border-radius: 2px 2px 0px 0px;
            }
        }
    }

    .subscrib-info:nth-child(2){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #FFEFDD;
                color: #333333 ;
                border-radius: 2px 2px 0px 0px;
            }
        }
    }

    .subscrib-info:nth-child(3){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #FBD6AD;
                color: #333333 ;
                border-radius: 2px 2px 0px 0px;
            }
        }
    }

    .subscrib-info:nth-child(4){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #5A627B;
                border-radius: 2px 2px 0px 0px;
            }
            p:nth-child(3){
                span{
                    font-size:32px;
                    font-weight: 500;
                    color:#333333;
                }
            }
        }
    }

    .subscrib-info:hover{
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.3);
        -moz-box-shadow: 0 0 20px rgba(0,0,0,0.3);
        -o-box-shadow: 0 0 20px rgba(0,0,0,0.3);
    }

}
.bottom-content{
    width: 100%;
    height:383px;
    background-image: url('../../assets/images/web/introduce/equity_bg.png');
    background-repeat:no-repeat;
    text-align: center;
    color: #fff; 
    padding-top: 60px;
    h1{
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
    }
}

.battle{
    margin-bottom:50px;
    table{
        text-align:center;
        font-size:14px;
        font-weight: 500;
        color: #333333;
        border-collapse: collapse;
    }
    table, td, th
    {
        border:1px solid #E6E6E6;
    }
    tr{
        height: 50px;
        .left-con{
            width: 4.31%;
            word-wrap: break-word;
        }
    }
    tr td{
        border-bottom: 0px;
    }
}
.question{
    margin-top: 50px;
    .ant-row{
        margin-bottom: 24px;
    }
}
.goon{
    width:50%;
    line-height:30px;
    margin:0 auto; background: linear-gradient(180deg, #FF992B 0%, #FF7B13 100%);
    border-radius: 15px;
    span{
        margin-top: 7px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }
}
.m_switch{
    /* /deep/ .ant-switch-inner{width: 200px;background-color: #ff0000} */
    width:216px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
    left:50%;
    margin-left: -108px;
    margin-top: 34px; 
}

.m_switch_inner{
    position: absolute;
    left: 2px;
    margin-top: 2px;
    width: 106px;
    height: 36px;
    background: #FF7D15;
    border-radius: 20px;
}
.m_switch_text_left{
    position: absolute;
    /* left: 12px; */
    
    font-size: 20px;
    line-height: 36px;
    width: 106px;
}
.m_switch_text_right{
    position: absolute;
    left: 106px;
    
    font-size: 20px;
    line-height: 36px;
    width: 106px;
    color: black;
}
.m_switch_tip{
    position: absolute;
    left: 70px;
    top: -10px;
}
.color_white{
    /* color: #fff; */
    animation: color_white 0.6s;
    animation-fill-mode: forwards;
}
.color_black{
    /* color: #000; */
    animation: color_black 0.6s;
    animation-fill-mode: forwards;
}
@keyframes color_white
{
  from {color: #000;}
  to { color: #fff;}
}
@keyframes color_black
{
  from { color: #fff;}
  to {color: #000;}
}
.move_left{
  animation: move_left 0.6s;
  animation-fill-mode: forwards;
}
.move_right{
  animation: move_right 0.6s;
  animation-fill-mode: forwards;
}
@keyframes move_right
{
  from {left: 2px;}
  to {left: 108px;}
}
@keyframes move_left
{
  from {left: 108px;}
  to {left: 2px;}
}
</style>